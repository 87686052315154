.container {
  display: flex;
}
main {
  width: 100%;
  padding: 2rem;
}
.sidebar {
  background: #543af2;
  color: #fff;
  transition: all 0.5s;
  min-height: 100%;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
}
.logo {
  font-size: 1.5rem;
}
.bars {
  display: flex;
  font-size: 1.8rem;
}
.link {
  display: flex;
  color: #fff;
  padding: 0.5rem 1rem;
  gap: 1rem;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover {
  background: #cac2fb;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: #cac2fb;
  color: #000;
}
.icon,
.link_text {
  font-size: 1.1rem;
}
.link_text {
  padding-top: 0.3rem;
}
.icon {
  padding-top: 0.2rem;
}
.logout-button {
  padding: 5%;
  width: 80%;
  font-size: 15px;
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
}
.logout-button:hover {
  background: #b6b6b6;
  color: #000000;
  transform: scale(1.1);
}
.logout-button:active {
  transform: scale(1);
}
.sidebar-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
