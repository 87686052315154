@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}
.wholetable {
  border: 1px solid black;
  width: 100%;
  height: fit-content;
  text-align: center;
  justify-content: center;
}
.wholetable th {
  border: 1px solid black;
  max-width: 100%;
}
tr {
  height: fit-content;
  max-width: 100%;
}
form {
  justify-content: center;
  align-items: center;
  display: flex;
}
td {
  border: 1px solid black;
}
.fetch-img {
  width: 20rem;
  height: 12rem;
}
.fetch-logo {
  width: 200px;
  height: 200px;
}
.cover-img {
  width: 10rem;
  height: 6rem;
}
.tablelogo {
  width: 13rem;
}
.Uploaded-Image {
  width: 20px;
  height: 12px;
}
.inputcard {
  padding: 3%;
  width: 40%;
  height: fit content;
  border: 1px solid gray;
  border-radius: 15px;
}
.inputcard2 {
  padding: 3%;
  width: 80%;
  height: fit content;
  border: 2px solid #eeeeee;
  border-radius: 15px;
}
.formdata {
  display: flex;
  flex-direction: column;
  height: 7.5rem;
}
.formdata label,
.formdata2 label {
  font-weight: 700;
  font-size: 1.3rem;
}
.formdata2 {
  height: 37rem;
}
.formdata input {
  padding: 3%;
  border-radius: 10px;
  border: 2px solid #eeeeee;
  outline: none;
  font-size: 1.1rem;
}
.formdata select {
  padding: 2%;
  border-radius: 10px;
  border: 2px solid #eeeeee;
  outline: none;
  font-size: 1.1rem;
}
.formbutton {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.formbutton button {
  width: fit-content;
  padding: 1.2rem;
  border-radius: 10px;
  border: 0px;
  font-size: 1.2rem;
  background-color: #eeebfe;
  color: #543af2;
}
.formbutton button:hover {
  background-color: #cac2fb;
  color: #432ec2;
  transform: scale(1.1);
}
.formbutton button:active {
  transform: scale(1);
}
.errorclass {
  color: red;
  font-size: 1rem;
  font-weight: 400;
}
.dividerLine {
  height: 5px;
  background-color: rgb(255, 0, 0);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

/* consulting Page */
.tabletitle {
  width: 20rem;
}
.tableimg {
  width: 25rem;
}
.tablebutton {
  width: 15rem;
}
.deletebutton {
  font-size: 5rem;
}
.icons {
  width: 40px;
  height: 40px;
}
.grid-item {
  position: relative;
}
.albumicons {
  width: 40px;
  height: 40px;
  right: 0%;
  position: absolute;
}
.album-heading {
  border-bottom: 2px solid black;
  margin: 0 0 3% 0;
  padding: 2% 0;
}
.icons:active {
  transform: scale(0.9);
}
.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d6d6d6;
  height: 100vh;
}
.login-page {
  width: fit-content;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.login-page h1 {
  color: #000000;
}
.login-form {
  display: grid;
  grid-template-columns: auto;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 350px;
  background-color: #fff;
}

.login-form Input {
  width: 300px;
  font-size: 1rem;
}

.login-button {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: fit-content;
  font-size: 15px;
  background: #000;
  color: #fff;
  border: none;
  outline: none;
}
.login-button:active {
  transform: scale(0.9);
}

.form-controls {
  display: flex;
  background-color: rgb(212, 212, 212);
  height: 2rem;
  border-top: 1px solid rgb(0, 0, 0);
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
}
.controls-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  width: 10%;
}
.display-images {
  width: 300px;
  height: 200px;
}
.controls-icon:hover {
  cursor: pointer;
}
.content-editor {
  min-height: 20rem;
  max-height: fit-content;
  border-bottom: 1px solid rgb(0, 0, 0);
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
  padding: 2%;
  outline: none;
  margin-bottom: 3rem;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  min-width: 100%;
  max-width: 100%;
}
.icon2 {
  width: 20px;
  height: 20px;
}
tbody {
  max-width: 400px;
}
.contenttabletitle {
  width: 10rem;
}
.contenttablecontent {
  width: 40rem;
}
.covertablecontent {
  width: 10rem;
}
.file-display {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1rem;
  grid-auto-rows: 170px;
  margin: 0 0 10% 0;
}

.album-images {
  width: 100%;
  height: 100%;
}

.album-images:active {
  transform: scale(0.9);
}
.albumforms {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.albumforms h2 {
  text-align: center;
}
.forms {
  padding: 0 0 10% 0;
}

.image-container {
  width: 100%; /* Set image width to 100% of parent container */
  height: 100%; /* Maintain aspect ratio */
  display: block;
}
.image-container {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0; /* Initially, set the height to 0 */
  background-color: rgba(0, 0, 0, 0.626); /* Semi-transparent black overlay */
  transition: height 0.5s; /* Transition for smooth height change */
  overflow: hidden; /* Hide any overflow */
}
.overlay-image {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0; /* Initially hide text */
  transition: opacity 0.5s; /* Transition for smooth opacity change */
}

.outerimg:hover .overlay {
  height: 100%; /* Expand overlay to cover the image on hover */
}
.outerimg:hover .overlay-image {
  opacity: 1; /* Show text on hover */
}
.edit-form {
  width: 100%;
}
.edit-formdata {
  display: flex;
  flex-direction: column;
  height: 5.5rem;
}

.edit-formdata label,
.content-label {
  font-weight: 700;
  font-size: 1rem;
}
.edit-formdata input {
  padding: 3%;
  border-radius: 10px;
  border: 2px solid #eeeeee;
  outline: none;
  font-size: 1rem;
}
.custom-modal {
  min-height: 1200px;
}
.edit-formdata select {
  padding: 2%;
  border-radius: 10px;
  border: 2px solid #eeeeee;
  outline: none;
  font-size: 1rem;
}
.edit-fetch-img {
  height: 10rem;
  width: 15rem;
}
.edit-inputcard {
  width: 100%;
}
.edit-content-editor {
  min-height: 20rem;
  max-height: fit-content;
  border-bottom: 1px solid rgb(0, 0, 0);
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
  padding: 2%;
  outline: none;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  min-width: 100%;
  max-width: 100%;
}
.ant-modal-wrap {
  overflow: none;
}
.contact-card {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 8fr 2fr;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 0 0 3% 0;
  padding: 1%;
}
.contact-details {
  padding: 2%;
}
.contact-details p {
  margin: 0 0 1em 0;
  font-size: 1rem;
}
.contact-details label {
  margin: 0 0 1em 0;
  font-weight: 700;
  font-size: 1rem;
}
.contact-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid gray;
}
.statuscontainer {
  display: flex;
  flex-direction: row;
}
.contact-button select {
  padding: 2% 0 0 2%;
  outline: none;
  border: none;
  background-color: transparent; /* Set background color as transparent */
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  top: 0%;
}
.contact-button label {
  padding: 2% 0 0 0;
  background-color: transparent; /* Set background color as transparent */
  font-size: 1rem;
  font-weight: 600;
}

.contact-card.Pending {
  background-color: rgb(255, 255, 214);
}

.contact-card.Recognised {
  background-color: rgb(214, 255, 228);
}

.contact-card.Decline {
  background-color: rgb(255, 214, 214);
}
.status-filter {
  margin: 0 0 3% 5%;
}
.status-filter label {
  font-size: 1.2rem;
  font-weight: 600;
}
.status-filter select {
  padding: 1% 2%;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  appearance: none;
}

.contenttablecontent img{
  width: 30rem;
  height: 25rem;
}

@media screen and (max-width: 450px) and (min-width: 380px) {
  .contenttablecontent img{
    width: 2rem;
    height: 1rem;
  }
}

